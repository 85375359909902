import gql from 'graphql-tag';

export const GLOBAL_SEARCH = gql`
 query GLOBAL_SEARCH(
    $rootItem: String!
    $keyword: String!
    $sortBy: String!
    $sortDesc: Boolean
    $first: Int 
)
{
  search(
    keyword: $keyword, 
    rootItem: $rootItem
    sortBy: $sortBy
    sortDesc: $sortDesc,
    first: $first
  ){
    results{
      totalCount,
      items {
        title: field(name: "title")
      }
    }
  }
}
`;