const FloatLabel = (() => {
  // add active class
  const handleFocus = (e) => {
    const target = e.target;
    target.parentNode.classList.add('focus-floatlabel');
    target.parentNode.classList.remove('active-floatlabel');
  };

  // remove active class
  const handleBlur = (e) => {
    const target = e.target;
    target.parentNode.classList.remove('focus-floatlabel');
    target.parentNode.classList.add('active-floatlabel');
    if (!target.value) {
      target.parentNode.classList.remove('active-floatlabel');
    }
    target.removeAttribute('placeholder');
  };

  // register events
  const bindEvents = (element) => {
    element.addEventListener('focus', handleFocus);
    element.addEventListener('blur', handleBlur);
  };

  // get DOM elements
  const init = () => {
    const floatContainers = document.querySelectorAll(
      'form input[type="text"],form input[type="password"], form input[type="email"], form input[type="zip"], form select,form textarea,form input[type="tel"],form input[type="date"],form input[type="datetime-local"],form input[type="month"],form input[type="number"],form input[type="search"],form input[type="time"],form input[type="url"],form input[type="week"]'
    );
    if (floatContainers.length > 0) {
      floatContainers.forEach((element) => {
        element.parentNode.classList.add('form-floatlabel');
        if (element.value) {
          element.parentNode.classList.add('active-floatlabel');
        }
        const labels = element.previousSibling;
        if (labels) {
          if (labels.innerHtml !== '') {
            element.removeAttribute('placeholder');
          }
        }
        bindEvents(element);
      });
    }
  };

  return {
    init: init,
  };
})();

export default FloatLabel;
