import React, { useEffect } from 'react';
import './index.scss';
import { RoutableRichText } from '../../react/RoutableRichText';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
const TextBlock = ({fields,sitecoreContext,rendering }) => {
  useEffect(() => {
    const Comp=document.querySelector('#TextBlock');
    var anchorscall = Comp.querySelectorAll('a,button');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if(element.innerHTML!="")
      {
      var id=sitecoreContext?.route?.name+"-ds:"+rendering.dataSource+"-TextBlock-"+element.innerHTML.replaceAll(" ", "-");
      
      element.setAttribute("id", id );
      }
      });
  }, []);
  return (
  <section className="article-text" id="TextBlock">
    {fields?.BodyText?.value && <RoutableRichText field={fields.BodyText} />}
  </section>
  );
};
export default withSitecoreContext()(TextBlock);
