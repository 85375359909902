import gql from 'graphql-tag';

export const SEARCH_Article_Listing = gql`
  query B2BBlogPosts(
    $rootItem: String!
    $fieldsEqual: [ItemSearchFieldQuery]
    $sortBy: String!
    $sortDesc: Boolean
    $first: Int
    $after: String!
  ) {
    search(
      fieldsEqual: $fieldsEqual
      rootItem: $rootItem
      sortBy: $sortBy
      sortDesc: $sortDesc
      first: $first
      after: $after
    ) {
      results {
        totalCount
        items {
          name
          fields {
            name
            value
          }
          item {
            url
            name
            id
            ... on ArticlePage {
              shortTitle {
                jss
              }
              shortDescription {
                jss
              }
              title {
                jss
              }
              publishedDate {
                jss
              }
              industry {
                targetItems {
                  name
                }
              }
              service {
                targetItems {
                  name
                }
              }
              globalTags {
                targetItems {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
