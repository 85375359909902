import { Form, createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms';
import { FieldValidationErrors } from './FieldValidationErrors';
import React, { useEffect, useRef, useState, Fragment, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';
import './index.scss';
const myCustomFormFactory = createDefaultFieldFactory();

myCustomFormFactory.setComponent('{0579CE20-1588-41A8-9A65-C16FFB254093}', (props) => {
  const {
    field: {
      model: { items },
    },
    value,
    onChange,
    errors,
    tracker,
  } = props;
  const formattedData = useMemo(() => {
    return items.map((item) => {
      return { ...item, label: item.value };
    });
  }, [items]);

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selected) => {
    setSelectedOption(selected);
    handleOnChange(props.field, selected.map((item) => item.label).join(','), onChange);
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
          <label>{props.value}</label>
        </components.Option>
      </div>
    );
  };
  
  const handleOnChange = (field, fieldValue, callback) => {
    let valid = true;
    const errorMessages = [];
    if (field.model.required && !fieldValue) {
      valid = false;
      errorMessages.push(`${field.model.title} is required`);
    }
    callback(field.valueField.name, fieldValue, valid, errorMessages);
  };
  
  return (
    <Fragment>
      <input
        type="checkbox"
        className="hidesection"
        id={`${props.field.valueField.id}`}
        name={`${props.field.valueField.name}`}
        onChange={(e) => handleOnChange(props.field, e.target.value, onChange)}
        onFocus={() => tracker.onFocusField(props.field, value)}
        onBlur={() => tracker.onBlurField(props.field, value, errors)}
        value={selectedOption?.map((item) => item?.label).join(',')}
      />
      <ReactSelect
        options={formattedData}
        isMulti={true}
        closeMenuOnSelect={false}
        className="react-select-container"
        classNamePrefix="react-select"
        hideSelectedOptions={false}
        placeholder={`${props.field.model.title ?? 'select....'}`}
        components={{
          Option,
        }}
        allowSelectAll={true}
        onChange={handleChange}
        value={selectedOption}
      />
      <FieldValidationErrors {...props} />
    </Fragment>
  );
});

myCustomFormFactory.setComponent('{7C34AC29-7B9E-4338-9EA0-05814A23D609}', (props) => {
  const [enteredValue, setEnteredValue] = useState('');
  const formFieldRef = useRef();
  const captchaInput = useRef();
  const { field, value, onChange, tracker, errors } = props;
  const grecaptcha_ready = () => {
    /*eslint-disable*/
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${props?.field?.model?.captchaPublicKey}`, { action: 'submit' })
        .then(function (token) {
          setEnteredValue(token);
        });
    });
    /*eslint-enable*/
  };

  const handleOnChange = (field, fieldValue, callback) => {
    let valid = true;
    const errorMessages = [];
    if (field.model.required && !fieldValue) {
      valid = false;
      errorMessages.push(`${field.model.title} is required`);
    }

    callback(field.valueField.name, fieldValue, valid, errorMessages);
  };

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (captchaInput?.current) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };

        document.body.appendChild(script);
      }
    };

    // load the script by passing the URL
    loadScriptByURL(
      `RecaptchaValidated`,
      `https://www.google.com/recaptcha/api.js?render=${props?.field?.model?.captchaPublicKey}`,
      function () {
        grecaptcha_ready();
      }
    );
    const focusCaptcha = () => {
      const parentForm = formFieldRef?.current.closest('form');
      if (parentForm) {
        const observerOptions = { root: null, rootMargin: '30px', threshold: 1.0 };
        function observerCallback(entries) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              captchaInput?.current.focus();
            }
          });
        }
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(parentForm);
      }
    };

    focusCaptcha();
    return () => {
      const recaptchaScriptId = document.querySelector('#RecaptchaValidated');
      const grecaptchabadge = document.querySelector('.grecaptcha-badge');
      if (recaptchaScriptId && grecaptchabadge) {
        recaptchaScriptId.remove();
        grecaptchabadge.remove();
      }
    };
  }, []);
  return (
    <fieldset ref={formFieldRef} className="hidesection">
      <label for={`${props.field.valueField.id}`} class=""></label>
      <textarea
        id={`${props.field.valueField.id}`}
        data-static="captchaInput"
        name={`${props.field.valueField.name}`}
        onChange={(e) => {
          handleOnChange(field, e.target.value, onChange);
        }}
        value={enteredValue}
        ref={captchaInput}
        onFocus={(e) => {
          handleOnChange(field, e.target.value, onChange);
        }}
        onBlur={(e) => {
          handleOnChange(field, e.target.value, onChange);
        }}
      />
    </fieldset>
  );
});

const JssSitecoreForm = ({ fields, history }) => {
  return (
    <Form
      form={fields}
      sitecoreApiHost={''}
      sitecoreApiKey={sitecoreApiKey}
      onRedirect={(url) => history.push(url)}
      fieldFactory={myCustomFormFactory}
    />
  );
};
export default withRouter(JssSitecoreForm);
