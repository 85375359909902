import React, { useRef, useEffect, useState } from 'react';
import {
  Image,
  Text,
  DateField,
  withSitecoreContext,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { RoutableRichText } from '../../react/RoutableRichText';
import { RoutableLink } from '../../react/RoutableLink';
import EbookModal from '../EbookModal';
import './index.scss';

const ArticlePageHero = (props) => {
  const [currentPageUrl, setPageURL] = useState(0);
  useEffect(() => {
    setPageURL(window.location.href);
  }, []);
  const { rendering, sitecoreContext, formPlaceholder } = props || {};
  const fielddata = rendering?.fields || sitecoreContext.route.fields;
  const isEditing = sitecoreContext?.pageEditing;
  const [placeholder, setPlaceholder] = useState(props.formPlaceholder || []);

  const print = () => {
    window.print();
  };

  const ref = useRef(null);
  const openModal = () => {
    ref?.current.classList.add('show-ebook-modal');
    const captchaInput = document.querySelector(
      '.article-page-hero textarea[data-static="captchaInput"]'
    );
    if (captchaInput) {
      captchaInput.focus();
    }
  };
  useEffect(() => {
    setPlaceholder(props.formPlaceholder || []);
    return () => {
      document.body.classList.remove('open-navigation');
      document.body.classList.remove('header__scroll-up');
    };
  }, [props.formPlaceholder]);

  return (
    <section className="article-page-hero" id="ArticlePageHero">
      <div className="article-page-hero__container">
        <div className="article-page-hero__inner-contain">
          <div className="article-page-hero__content">
            {fielddata?.ShowBreadCrumb?.value === true && (
              <div className="article-page-hero__pretitle-contain">
                <RoutableLink
                  field={fielddata?.BreadCrumb}
                  className="article-page-hero__pretitle"
                  data-tag-item={
                    sitecoreContext?.route?.name +
                    '-ArticlePage-Hero-' +
                    fielddata?.ShowBreadCrumb?.value.text
                  }
                />
              </div>
            )}
            {fielddata?.Title?.value && (
              <h1 className="article-page-hero__title">
                <Text field={fielddata.Title} />
              </h1>
            )}
            {fielddata?.ImageMedia?.value && (
              <div className="article-page-hero__mobile-image">
                {fielddata?.Link?.value?.href ? (
                  <a
                    href={fielddata.Link?.value?.href}
                    target={fielddata.Link?.value?.target?.replaceAll('|Custom', '')}
                  >
                    {fielddata?.LottieImage?.value != '' ? (
                      <div id="article-page-hero-mobileImage">
                        {fielddata?.LottieImage?.value.toLowerCase().includes('<lottie-player') ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: fielddata?.LottieImage?.value }}
                          />
                        ) : (
                          <Player
                            autoplay
                            speed={1}
                            loop
                            src={fielddata?.LottieImage?.value}
                          ></Player>
                        )}
                      </div>
                    ) : (
                      <Image field={fielddata.ImageMedia} id="article-page-hero-mobileImage" />
                    )}
                  </a>
                ) : fielddata?.LottieImage?.value != '' ? (
                  <div id="article-page-hero-mobileImage">
                    {fielddata?.LottieImage?.value.toLowerCase().includes('<lottie-player') ? (
                      <div dangerouslySetInnerHTML={{ __html: fielddata?.LottieImage?.value }} />
                    ) : (
                      <Player autoplay speed={1} loop src={fielddata?.LottieImage?.value}></Player>
                    )}
                  </div>
                ) : (
                  <Image field={fielddata.ImageMedia} id="article-page-hero-mobileImage" />
                )}
              </div>
            )}
            {fielddata?.ArticleDescription?.value && (
              <div className="article-page-hero__description">
                <RoutableRichText field={fielddata.ArticleDescription} />
              </div>
            )}
            {fielddata?.CTA?.value?.href && (
              <div className="article-page-hero__btn-contain">
                {fielddata?.CTA?.value?.href && (
                  <RoutableLink
                    field={fielddata.CTA}
                    className="article-page-hero__link"
                    datatag={
                      sitecoreContext?.route?.name +
                      '-ArticlePage-Hero-' +
                      fielddata?.CTA?.value.text
                    }
                  />
                )}
              </div>
            )}
            {placeholder?.find(
              (component) => component?.props?.rendering?.componentName === 'JssSitecoreForm'
            ) ? (
              <div className="article-page-hero__btn-contain">
                <button
                  className="article-page-hero__btn"
                  onClick={openModal}
                  data-tag-item={sitecoreContext?.route?.name + '-ArticlePage-Hero-Form-PopUP'}
                >
                  {fielddata?.FormCTALabel?.value}
                  <svg
                    role="presentation"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="24"
                    viewBox="0 0 21 24"
                  >
                    <path
                      d="M16.025 23.875h-11.5a4.312 4.312 0 0 1-4.312-4.313v-3.234a1.077 1.077 0 1 1 2.156 0v3.235c0 1.185.97 2.156 2.156 2.156h11.5c1.186 0 2.156-.97 2.156-2.157v-3.234a1.078 1.078 0 0 1 2.157 0v3.235a4.314 4.314 0 0 1-4.313 4.312zm-5.013-7.48c-.413.391-1.06.391-1.475 0l-6.11-5.75a1.073 1.073 0 0 1-.046-1.522 1.073 1.073 0 0 1 1.522-.05l4.294 4.039V1.953a1.077 1.077 0 1 1 2.156 0v11.159l4.291-4.036a1.077 1.077 0 1 1 1.477 1.57l-6.11 5.75z"
                      fill="#FFF"
                      fillRule="nonzero"
                    />
                  </svg>
                </button>
              </div>
            ) : null}
          </div>
          {fielddata?.ImageMedia?.value?.src && (
            <div className="article-page-hero__desktop-image">
              {fielddata.Link?.value?.href ? (
                <a
                  href={fielddata.Link?.value?.href}
                  target={fielddata.Link?.value?.target?.replaceAll('|Custom', '')}
                >
                  {fielddata?.LottieImage?.value != '' ? (
                    <div id="article-page-hero__image">
                      {fielddata?.LottieImage?.value.toLowerCase().includes('<lottie-player') ? (
                        <div dangerouslySetInnerHTML={{ __html: fielddata?.LottieImage?.value }} />
                      ) : (
                        <Player
                          autoplay
                          speed={1}
                          loop
                          src={fielddata?.LottieImage?.value}
                        ></Player>
                      )}
                    </div>
                  ) : (
                    <Image field={fielddata.ImageMedia} id="article-page-hero__image" />
                  )}
                </a>
              ) : fielddata?.LottieImage?.value != '' ? (
                <div id="article-page-hero__image">
                  {fielddata?.LottieImage?.value.toLowerCase().includes('<lottie-player') ? (
                    <div dangerouslySetInnerHTML={{ __html: fielddata?.LottieImage?.value }} />
                  ) : (
                    <Player autoplay speed={1} loop src={fielddata?.LottieImage?.value}></Player>
                  )}
                </div>
              ) : (
                <Image field={fielddata.ImageMedia} id="article-page-hero__image" />
              )}
            </div>
          )}
        </div>
        <div
          className={
            fielddata?.ImageMedia?.value?.src
              ? 'article-page-hero__inner-container'
              : 'article-page-hero__inner-container mt-set'
          }
        >
          {fielddata?.PublishedDate?.value && (
            <div className="article-page-hero__publish-date">
              <DateField
                field={fielddata.PublishedDate}
                tag="small"
                render={(date) =>
                  `${date.toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}`
                }
              />
            </div>
          )}
          <div className="article-page-hero__share-contain">
            <div className="article-page-hero__share-btn-content">
              <button
                className="article-page-hero__share-btn"
                aria-label="click here to open share widget"
                data-tag-item={sitecoreContext?.route?.name + '-ArticlePage-Hero-Open-Share-Widget'}
              >
                <svg
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                >
                  <path
                    d="M25 6.143a5.144 5.144 0 0 1-5.143 5.143 5.131 5.131 0 0 1-3.878-1.768l-4.806 2.405c.075.348.065.707.065 1.029 0 .418.01.777-.065 1.125l4.806 2.357c.942-1.034 2.33-1.768 3.878-1.768 2.84 0 5.143 2.304 5.143 5.191A5.144 5.144 0 0 1 19.857 25a5.114 5.114 0 0 1-5.143-5.143c0-.37.038-.728.113-1.077l-4.806-2.405a5.131 5.131 0 0 1-3.878 1.768C3.303 18.143 1 15.839 1 12.952a5.143 5.143 0 0 1 5.143-5.143c1.548 0 2.936.734 3.878 1.768l4.806-2.357a5.1 5.1 0 0 1-.113-1.077A5.113 5.113 0 0 1 19.857 1 5.143 5.143 0 0 1 25 6.143zM6.095 15.57c1.468 0 2.571-1.151 2.571-2.571 0-1.42-1.103-2.571-2.571-2.571-1.372 0-2.572 1.151-2.572 2.571 0 1.42 1.2 2.571 2.572 2.571zm13.762-12a2.572 2.572 0 1 0 .001 5.145 2.572 2.572 0 0 0 0-5.145zm0 18.858a2.572 2.572 0 1 0-.001-5.145 2.572 2.572 0 0 0 .001 5.145z"
                    fill="#FFF"
                    fillRule="nonzero"
                    stroke="#000"
                    strokeWidth=".75"
                    strokeOpacity=".9"
                  />
                </svg>
              </button>
              <div className="article-page-hero__share__inner-contain">
                <ul className="article-page-hero__share-list">
                  <li className="article-page-hero__share-list-item">
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="article-page-hero__share-list-item-link article-page-hero__share-list-item-link-facebook"
                      title="share on facebook"
                      data-tag-item={
                        sitecoreContext?.route?.name + '-ArticlePage-Hero-Share-on-Facebook'
                      }
                    >
                      <span> Facebook</span>
                    </a>
                  </li>
                  <li className="article-page-hero__share-list-item">
                    <a
                      href={`https://twitter.com/intent/tweet?${currentPageUrl}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      title="share on Twitter"
                      className="article-page-hero__share-list-item-link article-page-hero__share-list-item-link-twitter"
                      data-tag-item={
                        sitecoreContext?.route?.name + '-ArticlePage-Hero-Share-on-Twitter'
                      }
                    >
                      <span>Twitter</span>
                    </a>
                  </li>
                  <li className="article-page-hero__share-list-item">
                    <a
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentPageUrl}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      aria-label="share on facebook"
                      className="article-page-hero__share-list-item-link article-page-hero__share-list-item-link-linkdin"
                      title="share on LinkedIn"
                      data-tag-item={
                        sitecoreContext?.route?.name + '-ArticlePage-Hero-Share-on-LinkedIn'
                      }
                    >
                      <span> LinkedIn</span>
                    </a>
                  </li>
                  <li className="article-page-hero__share-list-item">
                    <a
                      href={`mailto:?&body=${currentPageUrl}`}
                      className="article-page-hero__share-list-item-link article-page-hero__share-list-item-link-mail"
                      title="share on Mail"
                      data-tag-item={
                        sitecoreContext?.route?.name + '-ArticlePage-Hero-Share-on-Mail'
                      }
                    >
                      <span>Mail </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="article-page-hero__print-btn"
              title="Print"
              aria-label="click here to print the page"
              data-tag-item={sitecoreContext?.route?.name + '-ArticlePage-Hero-Print'}
              onClick={print}
            >
              <svg
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="23"
                viewBox="0 0 24 23"
              >
                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                  <path d="M3 7h18a3 3 0 0 1 3 3v6H0v-6a3 3 0 0 1 3-3z" fill="#FFF" />
                  <circle fillOpacity=".9" fill="#000" cx="20.25" cy="10.25" r="1" />
                  <path
                    stroke="#FFF"
                    strokeWidth="1.5"
                    fillOpacity=".9"
                    fill="#000"
                    d="M3.75 13.75h16.5v7.5H3.75zM4 5.714V0h13.8L20 2v4"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <EbookModal className={`${isEditing ? 'editing' : ''}`} ref={ref}>
        {formPlaceholder}
      </EbookModal>
    </section>
  );
};

export default withSitecoreContext()(
  withPlaceholder({
    placeholder: 'jss-form',
    prop: 'formPlaceholder',
  })(ArticlePageHero)
);
