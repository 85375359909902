import React from 'react';
import './index.scss';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const ArticlePageContainer = ({ rendering }) => (
  <section className="article-page">
    <div className="article-page__container">
      <Placeholder name="jss-article-page-container" rendering={rendering} />
    </div>
  </section>
);

export default ArticlePageContainer;
