import { useQuery } from '@apollo/react-hooks';
import Constants from '../../Constants';
import { SEARCH_Article_Listing } from '../queries/ArticleListQuery';

export const useArticleReleasesQuery = ({
  page = 1,
  perPage = 10,
  rootItem = '3cb6f811-b20e-4104-8391-5db051e286ae',
  skipId = '',
  params={}
}) => {

  const service = params.service || "";
  const industry = params.industry || "";
  const globalTags = params.globalTags || "";

  const articleReleasePageTemplate = Constants?.SitecoreTemplateIds?.PressReleasePage?.replace(
    /-/g,
    ''
  );
  let serviceText = [];
  let industryText = [];
  let globalTagText = [];

  if (service !== '') {
    const selectedServices = service.split('|');
    selectedServices.forEach(item => {
      serviceText.push({name: 'service_sm', value:`${item}`});
    });
  }
  if (industry !== '') {
    const selectedIndustries = industry.split('|');
    selectedIndustries.forEach(item => {
      industryText.push({name: 'industry_sm', value:`${item}`});
    });
  }
  if (globalTags !== '') {
    const selectedGlobalTags = globalTags.split('|');
    selectedGlobalTags.forEach(item => {
      globalTagText.push({name: 'globaltags_sm', value:`${item}`});
    });
  }

  const fieldsEqual = [
    {
      name: '_template',
      value: articleReleasePageTemplate,
    },
  ];

  /*Filter by category, if applicable */

  /*Skip itemId in query, if applicable - ie, when showing related press releases we don't want to show current page*/
  if (skipId !== '' && skipId !== undefined) {
    fieldsEqual.push({ name: '_group', value: 'notin:' + skipId });
  }

  if(serviceText && serviceText.length > 0) {
    serviceText.forEach(element => {
      fieldsEqual.push(element);
    });
  }

  if(industryText && industryText.length > 0) {
    industryText.forEach(element => {
      fieldsEqual.push(element);
    });
  }

  if(globalTagText && globalTagText.length > 0) {
    globalTagText.forEach(element => {
      fieldsEqual.push(element);
    });
  }

  /*Filter by year, if applicable */

  const variables = {
    after: String((page - 1) * perPage),
    rootItem,
    fieldsEqual,
    first: perPage,
    sortBy: 'publishedDate',
    sortDesc: true,
  };

  const { data, ...rest } = useQuery(SEARCH_Article_Listing, { variables });

  return { data: data?.search?.results, ...rest };
};
