import React, { useState, useEffect, useRef, useMemo } from 'react';
import './index.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Pagination } from '../../react/Pagination';
import { useStandaloneSearchQuery } from '../../graphql/hooks/useStandaloneSearchQuery';
import { useGLobalSearchQuery } from '../../graphql/hooks/useGLobalSearchQuery';

const Search = ({ sitecoreContext = {}, params }) => {

  const showDataPerPage = params?.PageSize || 10;
  const [searchText, setSearchText] = useState("");
  const [autoSearchText, setAutoSearchText] = useState("");
  const [isAutoSearch, setAutoIsSearch] = useState(true);
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState({});
  const [autoFillParams, setAutoFillParams] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [hrefLocation, setHrefLocation] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setHrefLocation(window.location.origin);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const query = urlParams.get('q');
    if (query) {
      const decoreQuery = decodeURIComponent(query);
      setSearchText(decoreQuery);
      setTimeout(() => {
        setAutoIsSearch(false);
        setSearchParams({ keyword: decoreQuery });
      }, 500);
    }
  }, []);

  const { data: searchResponseData } = useStandaloneSearchQuery({
    page,
    perPage: showDataPerPage,
    rootItem: '01B4180A-FF6C-4EA5-98C0-CB6BEE9BC01F',
    params: searchParams,
  });

  const { data: globalSearchData } = useGLobalSearchQuery({
    page: 1,
    perPage: 10,
    rootItem: '01B4180A-FF6C-4EA5-98C0-CB6BEE9BC01F',
    params: autoFillParams,
  });

  const searchListRef = useRef(null);
  const executePageCall = () => {
    window.scrollTo({
      top: searchListRef.current.offsetTop - 50,
      behavior: 'smooth',
    });
  };

  const pageCount = useMemo(() => {
    return Math.ceil(resultCount / showDataPerPage)
  }, [resultCount]);

  const handleSearch = () => {
    if (searchText && searchText.length > 2) {
      setSearchParams({ keyword: searchText });
      setIsSearch(true);
    }
    else {
      setIsSearch(false);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
      handleBlur();
    }
  }

  const handleSVGClick = (event) => {
    event.preventDefault();
    handleSearch();
    handleBlur();
  }

  const handleAutoTextPush = (event) => {
    event.preventDefault();
    if (event.target.innerText) {
      setSearchText(event.target.innerText);
      setListData([]);
      setSearchParams({ keyword: event.target.innerText });
      handleBlur();
    }
  }

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (autoSearchText && autoSearchText.length > 2) {
      setAutoFillParams({ keyword: autoSearchText });
      setIsSearch(true);
    }
    else {
      setIsSearch(false);
    }
  }, [autoSearchText]);

  useEffect(() => {
    if (searchText && searchText.length > 2 && isAutoSearch) {
      const getData = setTimeout(() => {
        setAutoSearchText(searchText);
      }, 500)
      return () => clearTimeout(getData)
    }
    else {
      setListData([]);
    }
  }, [searchText]);

  useEffect(() => {
    handleSearch();
  }, [page]);

  useEffect(() => {
    if (searchResponseData && isSearch) {
      const responseData = Array.from(searchResponseData?.items);
      const fatchedResponse = responseData.filter(v => v.title !== null);
      setResultCount(searchResponseData.totalCount || 0);
      setData(fatchedResponse);
      setAutoIsSearch(true);
    }
  }, [searchResponseData]);

  useEffect(() => {
    if (globalSearchData && isSearch) {
      const responseData = Array.from(globalSearchData?.items);
      const fatchedResponse = responseData.filter(v => v.title !== null);
      setListData(fatchedResponse);
      setAutoIsSearch(true);
    }
  }, [globalSearchData]);

  return (
    <section className="search container" ref={searchListRef}>
      <div className='search-container-wrapper'>
        <div className='search_input'>
          <div className='search_input_wrapper'>
            <input onFocus={handleOnFocus}  type='text' name='search_box' value={searchText} className='search_input_wrapper_textbox' onChange={(event) => setSearchText(event.target.value)} onKeyDown={handleKeyDown} />
            <svg onClick={handleSVGClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="48px" height="48px"><path d="M 27 9 C 17.075 9 9 17.075 9 27 C 9 36.925 17.075 45 27 45 C 31.129213 45 34.9263 43.587367 37.966797 41.240234 L 51.048828 54.322266 C 51.952828 55.226266 53.418266 55.226266 54.322266 54.322266 C 55.226266 53.418266 55.226266 51.952828 54.322266 51.048828 L 41.240234 37.966797 C 43.587367 34.9263 45 31.129213 45 27 C 45 17.075 36.925 9 27 9 z M 27 13 C 34.719 13 41 19.281 41 27 C 41 34.719 34.719 41 27 41 C 19.281 41 13 34.719 13 27 C 13 19.281 19.281 13 27 13 z" /></svg>
          </div>
        </div>
        <div className='search_result_suggestion' style={{ display: isFocused ? 'block': 'none'}}>
          <ul className='global-search-container_list'>
            {
              listData.length > 0 && listData.map((item, index) => {
                return (
                  item.title != undefined && item.title != null ?
                    <li key={'global_link_' + index} onClick={(e) => handleAutoTextPush(e)} className='global-search-container_list_item search-result-auto-suggestion-link'>
                      {item.title}
                    </li>
                    : ""
                )
              })
            }
          </ul>
        </div>
      </div>
      <div className='search_result_wrapper'>
        <div className='search_result_info'>
          <p className='search_result_info_text'>{resultCount <= 1 ? `${resultCount} result` : `${resultCount} results`}</p>
        </div>
        <div className='search_result_body'>
          <ul className='search_result_list'>
            {
              data.length > 0 && data.map((item, index) => {
                const ctaLink = item?.item?.link?.jss?.value.linktype === 'internal' ?
                  String(hrefLocation + item.item.link.jss.value.href) :
                  item?.item?.link?.jss?.value.href;
                return (
                  <li className='search_result_list_item' key={'result_' + index}>
                    <div className='search_result_list_item_eyebrow'>
                      {item.tag}
                    </div>
                    <div className='search_result_list_item_title'>
                      <h3>
                        <a href={ctaLink}>{item.title}</a>
                      </h3>
                    </div>
                    <div className='search_result_list_item_desc'>
                      {item.description}
                    </div>
                    <div className='search_result_list_item_link'>
                      <a href={ctaLink} className='search_result_list_item_link_text'>{ctaLink}</a>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
      <Pagination
        className="press-releases-index__pagination"
        onPageClick={setPage}
        page={page}
        executePageCall={executePageCall}
        pageCount={pageCount}
        currentpage={sitecoreContext?.route?.name}
      />
    </section>
  )
};

export default withSitecoreContext()(Search);