import React, { useRef, useEffect, memo, useState } from 'react';
import './index.scss';

const AddScript = ({ fields }) => {
  const scriptRef = useRef();
  useEffect(() => {
    const scripts = scriptRef?.current.querySelectorAll('script');
    const iframe = scriptRef?.current.querySelector('iframe');
    console.log('scripts', scripts);
    if (!iframe && scripts.length > 0) {
      console.log('Iframe ', iframe);
      if (!window.location.hash) {
        window.location = window.location + '#loaded';
        window.location.reload();
      }
    }
  }, []);
  return <div ref={scriptRef} dangerouslySetInnerHTML={{ __html: fields?.Code?.value }} />;
};
export default AddScript;
