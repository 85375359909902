import { useMemo } from 'react';

const range = (start, end) => Array.from({ length: end - start + 1 }, (_, idx) => idx + start);

export const usePagination = ({ page = 1, pageCount = 1, rangeCount = 4, separator = '...' }) => {
  return useMemo(() => {
    let adjacentLeftOffset = Math.floor((rangeCount - 1) / 2);
    let adjacentRightOffset = Math.ceil((rangeCount - 1) / 2);

    // In case of unequal left/right offsets, we must flip them
    // if the right is getting too close to the upper boundary.
    if (page + adjacentRightOffset > pageCount - 2) {
      let tempAdjacentLeftCount = adjacentLeftOffset;
      adjacentLeftOffset = adjacentRightOffset;
      adjacentRightOffset = tempAdjacentLeftCount;
    }

    // Ensure page range doesn't fall outside boundaries.
    const adjacentLeftPage = Math.max(page - adjacentLeftOffset, 1);
    const adjacentRightPage = Math.min(page + adjacentRightOffset, pageCount);

    // Ensure we only show a separator if there's at least 1 page between the first and last pages.
    const showSeparators = pageCount > rangeCount + 2;
    const showLeftSeparator = showSeparators && adjacentLeftPage > 2;
    const showRightSeparator = showSeparators && adjacentRightPage <= pageCount - 2;

    // Left Range = [1, 2, 3, 4, 5, '...', 32]
    if (!showLeftSeparator && showRightSeparator) {
      const leftRange = range(1, rangeCount);
      return [...leftRange, separator, pageCount];
    }

    // Right Range = [1, '...', 28, 29, 30, 31, 32]
    if (showLeftSeparator && !showRightSeparator) {
      const rightRange = range(pageCount - rangeCount + 1, pageCount);
      return [1, separator, ...rightRange];
    }

    // Middle Range = [1, '...', 25, 26, 27, '...', 32]
    if (showLeftSeparator && showRightSeparator) {
      const middleRange = range(adjacentLeftPage, adjacentRightPage);
      return [1, separator, ...middleRange, separator, pageCount];
    }

    // Total Range = [1, 2, 3, 4, 5]
    return range(1, pageCount);
  }, [page, pageCount, rangeCount, separator]);
};
