import React, { useEffect } from 'react';
import { RoutableRichText } from '../../react/RoutableRichText';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
const ArticlePage = (props) => {
  const { fields, sitecoreContext } = props || {};
  const fielddata = fields || sitecoreContext.route.fields;
  useEffect(() => {
    const Comp=document.querySelector('#ArticlePage');
    var anchorscall = Comp.querySelectorAll('a');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if(element.innerHTML!="")
      {
      var id=sitecoreContext?.route?.name+"-Article-Page-"+element.innerHTML.replaceAll(" ", "-");
      
      element.setAttribute("id", id );
      }
     
      });
  }, []);
  return (
    
    <div className="article-page__inner-content" id="ArticlePage">    
      {fielddata?.Summary?.value && 
        <div className="article-page-summary">
          <RoutableRichText field={fielddata.Summary}/>
        </div>
      }
      
    </div>
    
    
  );
};

export default withSitecoreContext()(ArticlePage);
