import React, { useRef, useEffect } from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';

function debounce(callback, wait) {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

const Statistics = ({ fields = {} }) => {
  const { BackgroundImage, children = [] } = fields;
  const listRef = useRef();
  const statisticsRef = useRef();

  useEffect(() => {
    let previousY = -1;

    function getPercentInView(element) {
      const viewTop = window.pageYOffset;
      const viewBottom = viewTop + window.innerHeight;
      const rect = element?.getBoundingClientRect();
      const elementTop = rect.top + viewTop;
      const elementBottom = elementTop + rect.height;

      if (elementTop >= viewBottom || elementBottom <= viewTop) {
        // heigher or lower than viewport
        return 0;
      } else if (elementTop <= viewTop && elementBottom >= viewBottom) {
        // element is completely in viewport and bigger than viewport
        return 100;
      } else if (elementBottom <= viewBottom) {
        if (elementTop < viewTop) {
          // element intersects viewport top
          return Math.round(((elementBottom - viewTop) / rect.height) * 100);
        } else {
          // element is completely inside viewport
          return Math.round(((elementBottom - elementTop) / rect.height) * 100);
        }
      } else {
        // element intersects viewport bottom
        return Math.round(((viewBottom - elementTop) / rect.height) * 100);
      }
    }

    const makeUpdates = (activeIndex, currentActiveIndex, statisticsItems) => {
      if (activeIndex === currentActiveIndex) {
        return;
      }
      let numOfUpdates =
        activeIndex >= 0 ? activeIndex - currentActiveIndex : currentActiveIndex + 1;
      if (statisticsItems?.length < 0) {
        return;
      }
      for (let i = 0; i < Math.abs(numOfUpdates); i++) {
        setTimeout(() => {
          let index = i + 1;
          statisticsItems.forEach((el) => el.classList.remove('active'));
          statisticsItems[activeIndex + index]?.classList.add('active');
        }, 500 * (i + 1));
      }
    };

    const onScroll = (event) => {
      if (!listRef?.current) {
        return;
      }
      const isScrollingDown = window.scrollY > previousY + 10;
      const isScrollingUp = window.scrollY + 10 < previousY;
      const items = Array.from(listRef?.current.children);
      let currentActiveIndex = -1;
      const activeIndex = items.findIndex((x) => x.classList.contains('active'));

      if (!items?.length) {
        return;
      }

      const percentOfItemsVisible = items?.map((item) => getPercentInView(item));
      if (percentOfItemsVisible?.some((x) => x > 0)) {
        if (isScrollingDown) {
          currentActiveIndex = percentOfItemsVisible.findLastIndex((x) => x === 100);
          makeUpdates(activeIndex, currentActiveIndex, items);
        } else if (isScrollingUp) {
          //reverse list and active index variables to animate in reverse
          let reversedActiveIndex = activeIndex;
          if (reversedActiveIndex === 0) {
            reversedActiveIndex = items.length - 1;
          } else if (reversedActiveIndex === items.length - 1) {
            reversedActiveIndex = 0;
          }

          let reversedItems = items;
          reversedItems.reverse();
          let reversedPercentItemsVisible = percentOfItemsVisible;
          reversedPercentItemsVisible.reverse();
          currentActiveIndex = reversedPercentItemsVisible.findLastIndex((x) => x === 100);
          makeUpdates(reversedActiveIndex, currentActiveIndex, reversedItems);
        }
      }
      previousY = window.scrollY;
    };

    const debouncedOnScroll = debounce(onScroll, 10);
    window.addEventListener('scroll', debouncedOnScroll);

    return () => window.removeEventListener('scroll', () => debouncedOnScroll);
  }, [listRef]);

  return (
    <section className="statistics" ref={statisticsRef}>
      <Image field={BackgroundImage}></Image>
      <ul className="statistics__content" ref={listRef}>
        {children.length > 0 &&
          children.slice(0, 3).map(({ fields }, index) => {
            return (
              <li key={`statistic-${index}`}>
                <Text field={fields?.Value} tag="span" className="statistics__value" />
                <div className="statistics__details">
                  <Text
                    field={fields?.SubtextOne}
                    tag="span"
                    className={fields?.SubtextOne?.value?.length === 1 ? 'symbol' : ''}
                  />
                  <Text field={fields?.SubtextTwo} tag="span" />
                </div>
              </li>
            );
          })}
      </ul>
    </section>
  );
};

export default Statistics;
