import React from 'react';
import { Placeholder,  withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ArticleSchema from '../../react/ArticleSchema/Index';
const social = {
  description: "",
 
};
const ArticleContent = ({ rendering,sitecoreContext }) => (
 
  <>
  <ArticleSchema SitecoreContextData={sitecoreContext}/>
  <Placeholder name="jss-article-content" rendering={rendering} />
  </>
);

export default withSitecoreContext()(ArticleContent);