const imagesLoaded = require('imagesloaded');

// Preload images
const preloadImages = (selector = 'img', compRef) => {
  return new Promise((resolve) => {
    imagesLoaded(compRef?.current?.querySelectorAll(selector), { background: true }, resolve);
  });
};

export { preloadImages };
