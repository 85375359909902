const Constants = {
  SitecoreTemplateIds: {
    LinkList: 'bd065a02-4109-58d1-be4e-f0da06e09609',
    MediaIconList: 'a4dab87f-4062-5dfc-b673-21ecdf695b7c',
    BottomLinkList: 'f72949df-0707-5dac-a652-41d3589bd909',
    Link: '169a9cc7-cc0b-4152-bf35-d517514bfe06',
    MediaLink: '9d7ef8df-c932-5f0b-836a-3f397957f5e7',
    MegaMenuList: '926f9311-3578-5c55-a9c9-e5c4dd23d0b5',
    CallToActionLink: '2cbb83f4-f021-5e5d-a5d1-61362c2a0e6d',
    FAQCategory: 'cbd6a23f-a679-4f82-ac3a-67de5e925439',
    FAQ: 'd3801bbb-c7b8-4085-9379-b731fac693c0',
    PageData: '1c82e550-ebcd-4e5d-8abd-d50d0809541e',
    Animation: '58b660c8-4461-4253-871d-bda37ba1b553',
    Image: 'd7c248cb-deee-404b-9de9-e8033e2174cf',
    ResourceDetailPage: '78f4fafe-f54c-4964-bd90-e10229298de1',
    Folder: 'a87a00b1-e6db-45ab-8b54-636fec3b5523',
    Quote: '731fc640-0183-4db9-9d08-60d1a5a17e78',
    Carrier: '2de07a3d-d138-4324-aa8c-defa478744f9',
    LocationPage: 'e3561444-e0b4-454f-bb8d-bb4d501b68f9',
    LabelledLink: 'd1c2b082-60f0-4432-ba40-77cd83a7ed02',
    ContactCard: '631d0ba2-fa39-4cf4-a3df-8520fbe27f28',
    PressReleasePage: '3933b6d4-3ccd-4406-a6cb-5a8bfa966607',
    ExternalPressRelease: '3933b6d4-3ccd-4406-a6cb-5a8bfa966607',
    HomePage: 'ab606403-7ee4-443d-bde9-e61e0f8a2fde',
    FooterColumnId: 'ba68f89c-8a7b-4e79-8af5-b63cb765f78f',
    FooterDividerId: '8c477309-c600-4a02-b5b5-6638e55c778d',
    FooterRowId: '6519548d-cb95-46be-a987-896a3fcd3fc5',
  },
  SitecoreNodeID: {
    SeoIndustryTags: 'bcd3772f-de13-4474-96f8-78df55431b49',
    SeoServiceTag: '9cfffafe-9adc-4347-a771-d64eda35482d',
    SeoGlobalTags: 'f7852c15-2df5-449a-bc1d-24e8a884da2f',
  },
  Variables: {
    CurrentYear: '{CurrentYear}',
    Number: '{number}',
    Type: '{type}',
  },
  CSS: {
    BackgroundClass: 'background',
    DisplayContentCard: 'displayContentCard',
    GlobalHeaderHeight: 75, // Must be in sync with $global-header-height
    GlobalNavigationTransitionMs: 350, // Must be in sync with $global-navigation-transition-ms
    GridXlMin: 1200, // Must be in sync with $grid-breakpoints xl
    GridLgMin: 992, // Must be in sync with $grid-breakpoints lg
    GridMdMin: 744, // Must be in sync with $grid-breakpoints md
  },
  /**
   * Special css classes that when added trigger custom javascript
   */
  TriggerClasses: {
    OpensDriftChat: 'js-opensDriftChat',
    OpensModal: 'js-openModal',
  },
};

export default Constants;
