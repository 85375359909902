import React, { useEffect } from 'react';
import './index.scss';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

function FeaturedItems({ fields, sitecoreContext, rendering }) {
  const { Title, children = [], FeaturedItem = [] } = fields;
  const selectedItems = FeaturedItem.some((item) => item !== null) ? FeaturedItem : children;
  function linkText(cta) {
    if (cta.value.linktype == 'internal' && !cta.value.text) {
      // takes the page route, splits and takes the page name after the last /, replaces all - with blank space to create
      // words, and then capitalizes each word. i.e. home/test/generic-page => Generic Page
      return cta.value.href
        .split('/')
        .pop()
        .replaceAll('-', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());
    }
    return cta.value.text;
  }

  return (
    <div className="featured-items" id="FeaturedItems">
      <div className="featured-items-container">
        <h4 className="featured-items__title">
          <Text field={Title} />
        </h4>
        {children && (
          <div className="featured-items__contain">
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
              }}
              modules={[Navigation]}
              navigation={true}
              spaceBetween={30}
              speed={900}
            >
              {selectedItems.map((items, index) => {
                return (
                  <SwiperSlide key={index}>
                    {items?.fields?.Cta?.value?.href ? (
                      <>
                        {items?.fields?.Cta?.value?.linktype == 'external' ? (
                          <a
                            href={items?.fields?.Cta?.value.href}
                            className="featured-items__contain-link"
                            target="__blank"
                            rel="noopener noreferrer"
                            data-tag-item={
                              sitecoreContext?.route?.name +
                              '-ds:' +
                              rendering.dataSource +
                              '-Featured-Items-' +
                              items?.fields?.Cta?.value?.text
                            }
                          >
                            {items?.fields?.Media && (
                              <div className="featured-items__contain-img">
                                <Image media={items.fields.Media} />
                              </div>
                            )}
                            {items?.fields?.Title && (
                              <h4 className="featured-items__contain-title">
                                <Text field={items.fields.Title} />
                              </h4>
                            )}
                            {items?.fields?.Description && (
                              <p className="featured-items__contain-description">
                                <Text field={items.fields.Description} />
                              </p>
                            )}
                            {items?.fields?.Cta && (
                              <div className="featured-items__contain-link-item">
                                <div className="featured-items__contain-link-item-text">
                                  <span>{linkText(items?.fields?.Cta)}</span>
                                  <svg
                                    role="presentation"
                                    width="13"
                                    height="21"
                                    viewBox="0 0 13 21"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m1.727 1.724 8.99 9.098-8.943 9.05"
                                      stroke="#0050ff"
                                      strokeWidth="2.25"
                                      fill="none"
                                      fillRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </a>
                        ) : (
                          <Link
                            to={items?.fields?.Cta?.value.href}
                            className="featured-items__contain-link"
                            data-tag-item={
                              sitecoreContext?.route?.name +
                              '-ds:' +
                              rendering.dataSource +
                              '-Featured-Items-' +
                              items?.fields?.Cta?.value?.text
                            }
                          >
                            {items?.fields?.Media && (
                              <div className="featured-items__contain-img">
                                <Image media={items.fields.Media} />
                              </div>
                            )}
                            {items?.fields?.Title && (
                              <h4 className="featured-items__contain-title">
                                <Text field={items.fields.Title} />
                              </h4>
                            )}
                            {items?.fields?.Description && (
                              <p className="featured-items__contain-description">
                                <Text field={items.fields.Description} />
                              </p>
                            )}
                            {items?.fields?.Cta && (
                              <div className="featured-items__contain-link-item">
                                <div className="featured-items__contain-link-item-text">
                                  <span>{linkText(items?.fields?.Cta)}</span>
                                  <svg
                                    role="presentation"
                                    width="13"
                                    height="21"
                                    viewBox="0 0 13 21"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m1.727 1.724 8.99 9.098-8.943 9.05"
                                      stroke="#0050ff"
                                      strokeWidth="2.25"
                                      fill="none"
                                      fillRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </Link>
                        )}
                      </>
                    ) : (
                      <div className="featured-items__contain-item">
                        {items?.fields?.Media && (
                          <div className="featured-items__contain-img">
                            <Image media={items.fields.Media} />
                          </div>
                        )}
                        {items?.fields?.Title && (
                          <h4 className="featured-items__contain-title">
                            <Text field={items.fields.Title} />
                          </h4>
                        )}
                        {items?.fields?.Description && (
                          <p className="featured-items__contain-description">
                            <Text field={items.fields.Description} />
                          </p>
                        )}
                      </div>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
}
export default withSitecoreContext()(FeaturedItems);
