import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Text, DateField, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import './index.scss';
import { Pagination } from '../../react/Pagination';

const EventListing = ({ sitecoreContext = {}, params }) => {
    const eventReleasesPerPage = params?.PageSize || 10;
    const aditionalParams = params?.AditionalParams || '';
    const linkText = params?.LinkText || 'Register Now';
    const fetchAPIUrl = params?.APIBaseURL || 'https://www.wipfli.com/';
    const [currentData, setCurrentData] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const CharCount = params.CharCountShortDesc ? parseInt(params.CharCountShortDesc) : 0;

    const eventListRef = useRef(null);
    const executePageCall = () => {
        window.scrollTo({
            top: eventListRef.current.offsetTop - 50,
            behavior: 'smooth',
        });
    };

    const pageCount = useMemo(
        () => {
            return Math.ceil(currentData?.length / eventReleasesPerPage)
        },
        [currentData?.length]
    );

    function formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return month + ' ' + day + ',' + ' ' + year;
    }

    useEffect(() => {
        const apiUrl = aditionalParams != '' ? `${fetchAPIUrl}&${aditionalParams}` : fetchAPIUrl;
        const fetchEvents = async () => {
            try {
                await fetch(apiUrl)
                    .then((response) => { return response.json() })
                    .then((json) => {
                        setCurrentData(json);
                        const fatchedResponse = Array.from(json);
                        const skipPage = (page - 1) * eventReleasesPerPage;
                        const takePage = (fatchedResponse.length >= skipPage) ? skipPage + Number(eventReleasesPerPage) : (fatchedResponse.length - skipPage);
                        const filterData = fatchedResponse.slice(skipPage, takePage);
                        setData(filterData);
                    })
                    .catch(error => console.error(error));
            } catch (error) {
                console.log(error);
            }
        };
        fetchEvents();
    }, [page]);

    return (
        <section className="eventlisting" id="EventListing" ref={eventListRef}>
            <div className="eventlisting__container">
                <ul className="eventlisting__list">
                    {data && data.map((result, index) => (
                        <li className="eventlisting__list-item" key={`EventListItem-${index}`}>
                            <div className="eventlisting__list-item-content">
                                <div className="eventlisting__list-item-link">
                                    {result?.Date && (
                                        <div className="eventlisting__list-item-date">
                                            <small>{formatDate(result?.Date)}</small>
                                        </div>
                                    )}
                                    {result?.Title && (
                                        <h2 className="eventlisting__list-item-title">
                                            <a href={'https://' + result?.PageUrl} target='_self' rel="noopener noreferrer">{result?.Title}</a>
                                        </h2>
                                    )}
                                    {result?.Details && (
                                        <div className="eventlisting__list-item-innercontent" dangerouslySetInnerHTML={{ __html: result?.Details.slice(0, CharCount) }}></div>
                                    )}
                                    <div className="eventlisting__list-item-outercontent">
                                        <div className="eventlisting__list-item-links-contain">
                                            <a href={'https://' + result?.PageUrl} target='_self' rel="noopener noreferrer" className="eventlisting__list-item-links">
                                                <span>{i18n.t(linkText)}</span>
                                                <svg
                                                    role="presentation"
                                                    width="13"
                                                    height="21"
                                                    viewBox="0 0 13 21"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="m1.727 1.724 8.99 9.098-8.943 9.05"
                                                        stroke="#0050ff"
                                                        strokeWidth="2.25"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <Pagination
                    className="press-releases-index__pagination"
                    onPageClick={setPage}
                    page={page}
                    executePageCall={executePageCall}
                    pageCount={pageCount}
                    currentpage={sitecoreContext?.route?.name}
                />
            </div>
        </section>
    );
};

export default withSitecoreContext()(EventListing);