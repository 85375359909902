import React, { useEffect, useRef } from 'react';
//import Plyr from 'plyr';
import './index.scss';
import { default as Button } from '../Button';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

function HomePageHero(props) {
  const { fields, sitecoreContext } = props;
  const isEditing = sitecoreContext?.pageEditing;
  const {
    FontsColor,
    VideoSource,
    VideoMedia,
    VideoId,
    MobileVideoSource,
    MobileVideoMedia,
    MobileVideoId,
    ImageMedia,
    CTA,
    CTATitle,
    Headline1,
    Headline2,
    VariationType,
  } = fields || {};

  const HomePageHeroRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const Comp = document.querySelector('#HomePageHero');
    var anchorscall = Comp.querySelectorAll('a,button');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if (element.innerHTML != '') {
        var id =
          sitecoreContext?.route?.name +
          '-Home-Page-Hero-' +
          element.innerHTML.replaceAll(' ', '-');

        element.setAttribute('id', id);
      }
    });
    //loadYoutube();
    if (videoRef.current !== null) {
      videoRef.current.defaultMuted = true;
      videoRef.current.muted = true;

      if (
        !!(
          videoRef.currentTime > 0 &&
          !videoRef.paused &&
          !videoRef.ended &&
          videoRef.readyState > 2
        )
      ) {
        videoRef.play();
      }
    }
    return () => {
      document.body.classList.remove('open-navigation');
      document.body.classList.remove('header__scroll-up');
      document.body.classList.remove('header__scroll-down');
    };
  }, []);

  if (!fields && !isEditing) {
    return null;
  }

  const renderMedia = () => {
    const mediaType = VariationType?.value || '';
    const videoSource = VideoSource?.fields?.Text?.value || '';
    const videoId = VideoId?.value || '';
    const videoSrc = VideoMedia?.value?.src || '';
    const mobileVideoSource = MobileVideoSource?.fields?.Text?.value || '';
    const mobileVideoId = MobileVideoId?.value || '';
    const mobileVideoSrc = MobileVideoMedia?.value?.src || '';

    if (mediaType === 'Video' && videoSource) {
      if (videoSource.toLowerCase() === 'medialibrary') {
        // Video File
        const mediaAlt = VideoMedia?.value?.title || VideoMedia?.value?.description || '';
        const mobileMediaAlt =
          MobileVideoMedia?.value?.title || MobileVideoMedia?.value?.description || '';
        return (
          <>
            <div className="desktop">
              <video
                {...{ muted: 'true', autoplay: 'true', loop: 'true', playsinline: 'true' }}
                alt={mediaAlt}
                name={mediaAlt}
                ref={videoRef}
              >
                <source src={videoSrc} type="video/mp4" />
              </video>
            </div>
            <div className="mobile">
              <video
                {...{ muted: 'true', autoplay: 'true', loop: 'true', playsinline: 'true' }}
                alt={mobileMediaAlt}
                name={mobileMediaAlt}
                ref={videoRef}
              >
                <source src={mobileVideoSrc} type="video/mp4" />
              </video>
            </div>
          </>
        );
      }

      const desktop_MediaAlt = VideoSource?.name;
      const mobile_MediaAlt = MobileVideoSource?.name;
      // Vimeo Video
      if (videoSource.toLowerCase() === 'vimeo') {
        return (
          <>
            <div className="desktop">
              <iframe
                title={desktop_MediaAlt}
                src={`https://player.vimeo.com/video/${videoId}?background=1&muted=1`}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
            <div className="mobile">
              <iframe
                title={mobile_MediaAlt}
                src={`https://player.vimeo.com/video/${mobileVideoId}?background=1&muted=1`}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </>
        );
      }
    }

    // Image
    return <Image field={ImageMedia} />;
  };

  const scrollToNextComponent = () => {
    if (HomePageHeroRef.current && HomePageHeroRef.current.nextSibling) {
      HomePageHeroRef.current.nextSibling.scrollIntoView();
    }
  };
  return (
    <>
      {FontsColor?.value ? (
        <style>
          {`:root {
           --homepage-hero-color: ${FontsColor.value};
        }`}
        </style>
      ) : null}
      <div className={`homepage-hero`} ref={HomePageHeroRef} id="HomePageHero">
        <div className="homepage-hero__bg">{renderMedia()}</div>
        <div className="homepage-hero__overlay"></div>
        <div className="homepage-hero__content">
          <h1>
            {ImageMedia?.editable ? (
              <Text field={Headline1} tag={'span'} />
            ) : (
              <Text field={Headline1} tag={'span'} />
            )}

            <Text field={Headline2} tag={'span'} />
          </h1>
          {CTA?.value?.href ? (
            <Button field={CTA} variant={'btn-right-transparent'} />
          ) : (
            <button className="button btn-right-transparent" onClick={scrollToNextComponent}>
              {CTATitle?.value}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default withSitecoreContext()(HomePageHero);
