import React from 'react';
import Helmet from 'react-helmet';

function HeaderScriptTags({ scriptArray = [] }) {
  return (
    <Helmet>
      {scriptArray.map((script) => {
        const scriptProps = {};
        if (script.id) scriptProps.id = script.id;
        if (script.url) scriptProps.src = script.url;
        
        if (script.attributes)
        {
          script.attributes?.split('&')?.forEach((attr) => { 
          const [facetName, facetValue]=attr?.split('=');
          if(facetName && facetName!==''){
            var newInput = `${facetName}`;
            if(facetValue===undefined){
              scriptProps[newInput] ='';
            }
            else
            {
              scriptProps[newInput] = facetValue;
            }
          }
         });
        }
        return (
          <script key={script.id} {...scriptProps} type="text/javascript">
            {script.code}
          </script>
        );
      })}
    </Helmet>
  );
}

export default HeaderScriptTags;
