import React, { useRef, useState, useEffect } from 'react';
import './index.scss';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from '../Button';
import { Link } from 'react-router-dom';
import { RoutableRichText } from '../../react/RoutableRichText';

function Accordion({ fields, sitecoreContext, rendering }) {
  const { Title, Description, CTA, children = [] } = fields;

  const [active, setActive] = useState(null);
  const AccordionRef = useRef();
  useEffect(() => {
    const Comp = document.querySelector('#Accordion');
    var anchorscall = Comp.querySelectorAll('a,button');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if (element.innerText != "") {
        var id = sitecoreContext?.route?.name + "-ds:" + rendering.dataSource + "-Accordion-" + element.innerText.replaceAll(" ", "-");

        element.setAttribute("id", id);
      }
    });

    setTimeout(function () {
      var accordionSections = document.querySelectorAll('.accordion__items-contain-desc');

      for (let index = 0; index < accordionSections.length; index++) {
        const element = accordionSections[index];
        const accordionSectionLink = element.querySelector('.accordion__items-contain-desc-link');
        const childContainer = element.querySelector('.accordion__items-contain-desc-body').childNodes[0]; // div
        const childContainerLength = childContainer.childNodes.length - 1;
        const nodeToAppend = (childContainerLength == 0) ? 
                           childContainer.childNodes[childContainerLength] : 
                           childContainer.childNodes[childContainerLength];
                           
        if(nodeToAppend.nodeName == '#text' || nodeToAppend.nodeType == 3) {
          childContainer.appendChild(accordionSectionLink);
        } else {
          nodeToAppend.appendChild(accordionSectionLink);
        }
      }

    }, 1000);

  }, []);
  
  const toggle = (index) => {
    const container = AccordionRef?.current;
    const lists = container?.querySelectorAll('.accordion__items-lists');
    const title = container?.querySelectorAll('.accordion__items-contain-title');
    const icon = container?.querySelectorAll('.accordion__items-contain-icon');


    if (active == index) { //closeing
      lists.forEach((el) => {
        el.classList.remove("blurr");
      });
      title.forEach((el) => {
        el.classList.remove("blurr");
      });
      icon.forEach((el) => {
        el.classList.remove("blurr");
      });
      return setActive(null);
    }
    //Active
    setActive(index);
    lists.forEach((el, i) => {
      if (index != i) {
        el.classList.add("blurr");
      } else {
        el.classList.remove("blurr")
      }
    });
    title.forEach((el, i) => {
      if (index != i) {
        el.classList.add("blurr");
      } else {
        el.classList.remove("blurr")
      }
    });
    icon.forEach((el, i) => {
      if (index != i) {
        el.classList.add("blurr");
      } else {
        el.classList.remove("blurr")
      }
    });
  };

  function linkText(cta) {
    if (cta.value.linktype == "internal" && !cta.value.text) {
      // takes the page route, splits and takes the page name after the last /, replaces all - with blank space to create 
      // words, and then capitalizes each word. i.e. home/test/generic-page => Generic Page
      return cta.value.href.split('/').pop().replaceAll('-', ' ').replace(/(^\w{1})|(\s+\w{1})/g, l => l.toUpperCase());
    }
    return cta.value.text;
  }

  return (
    <div className="accordion" ref={AccordionRef} id="Accordion">
      <div className="accordion-container">
        <h4 className="accordion__title">
          <Text field={Title} />
        </h4>
        <p className="accordion__description">
          <Text field={Description} />
        </p>
        <ul className="accordion__items">
          {children.map((item, index) => {
            return (
              <li key={index} className="accordion__items-lists">
                <button
                  onClick={() => toggle(index)}
                  className="accordion__items-contain"
                  aria-expanded={active == index ? 'false' : 'true'}
                  aria-controls={`Accordion-${index}`}
                >
                  <h3 className="accordion__items-contain-title">
                    <Text field={item.fields.Title} />
                  </h3>
                  <span className="accordion__items-contain-icon">
                    {active == index ? (
                      <svg width="30" height="29" viewBox="0 0 30 29" xmlns="http://www.w3.org/2000/svg" className="accordion__items-contain-icon-collapse">
                        <g stroke-width="2" fill="none" fill-rule="evenodd">
                          <path d="M15 28C7.267 28 1 21.956 1 14.5 1 7.043 7.267 1 15 1s14 6.044 14 13.5S22.733 28 15 28z" stroke="#0050FF" fill="#0050FF" />
                          <path stroke="#FFF" stroke-linecap="square" d="M22 14.018H9" />
                        </g>
                      </svg>

                    ) : (
                      <svg
                        viewBox="0 0 30 30"
                        xmlns="http://www.w3.org/2000/svg"
                        className="accordion__items-contain-icon-expand"
                      >
                        <g stroke="#0050FF" strokeWidth="2" fill="none" fillRule="evenodd">
                          <path strokeLinecap="square" d="M15.5 8v13M22 14.5H9" />
                          <path d="M15 29C7.267 29 1 22.733 1 15S7.267 1 15 1s14 6.267 14 14-6.267 14-14 14z" />
                        </g>
                      </svg>
                    )}
                  </span>
                </button>
                <div
                  className={
                    active == index
                      ? 'accordion__items-contain-content-show'
                      : 'accordion__items-contain-content'
                  }
                  id={`Accordion-${index}`}
                >
                  <p className="accordion__items-contain-desc">
                    <RoutableRichText field={item.fields.Description} className='accordion__items-contain-desc-body'></RoutableRichText>
                    {item.fields.CTA.value.href && (
                      <Link
                        to={item.fields.CTA.value.href}
                        className="accordion__items-contain-desc-link"
                      >
                        {linkText(item.fields.CTA)}
                      </Link>
                    )}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
        {fields?.CTA?.value?.text && <Button field={fields.CTA} variant={'btn-white'} />}
      </div>
    </div>
  );
}

export default withSitecoreContext()(Accordion);

