import React from 'react';
import './index.scss';
import { RoutableRichText } from '../../react/RoutableRichText/index.js';
import Button from '../Button';

function Cta({fields}) {

  const { Title = {}, Description = {}, CtaOne = {}, IsLeftAligned ,CtaTwo = {},  } = fields;
  
  return (
    <div className={`cta ${IsLeftAligned?.value === false ? 'cta__right-align':'cta-container__right-align'}`}>
      <div className="cta-container">
        <div className="cta__left-align">
          <h2 className="cta__left-align-title">
            <RoutableRichText field={Title} />
          </h2>
        </div>
        <div className="cta__right-align">
          {Description?.value && (
            <div className="cta__right-align-description">
              <RoutableRichText field={Description} />
            </div>
          )}

          <div className="cta__right-align-btn">
            {CtaOne?.value?.href && <Button field={CtaOne} />}
            {CtaTwo?.value?.href && <Button field={CtaTwo} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cta;
