import axios from 'axios';
import config from './temp/config';

const fetchSitecoreGraphQL = async (query) => {
  const endpoint = `${config.graphQLEndpoint}`;
  try {
    const response = await axios.post(
      endpoint,
      { query },
      {
        headers: {
          'Content-Type': 'application/json',
          sc_apikey: `${config.sitecoreApiKey}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching Sitecore data:', error);
    return null;
  }
};

const fetchNodeDescendants = async (nodePaths) => {
  const queries = nodePaths.map(
    (path) => `
    {
      item(path: "${path}") {
        id
        displayName
        children {
          id
          name
          displayName
        }
      }
    }
  `
  );

  const promises = queries.map((query) => fetchSitecoreGraphQL(query));
  return Promise.all(promises);
};
export default fetchNodeDescendants;
