import React from 'react';
import Helmet from 'react-helmet';
function ArticleSchema({ SitecoreContextData = {} }) {
  const authors =
  SitecoreContextData?.route?.fields?.Author?.length > 0
    ? SitecoreContextData?.route?.fields?.Author?.map((prodData) => prodData?.fields?.Name?.value).join(
        ', '
      )
    : '';
  const Imagedata = {
    '@type':'ImageObject',
    'url':SitecoreContextData?.route?.fields?.OgImage?.value?.src||SitecoreContextData?.OgImage?.value?.src
   
  };
  
    const structuredData = {
      '@context':'http://schema.org',
      '@type':'Article',
      '@id':SitecoreContextData?.itemUrl,
      "name":SitecoreContextData?.route?.displayName,
      "description":SitecoreContextData?.route?.fields?.ShortDescription?.value||SitecoreContextData?.route?.fields?.MetaDescription?.value
      ,"image":   Imagedata,
      "mainEntityOfPage":SitecoreContextData?.itemUrl,
      "author":{"@type":"Person","name":authors},
      "dateModified":SitecoreContextData?.ModifiedDate,
      "datePublished":SitecoreContextData?.CreatedDate,
      "headline":SitecoreContextData?.route?.fields?.BrowserTitle?.value,
      "keywords":SitecoreContextData?.route?.fields?.MetaKeywords?.value,
      "publisher":{"@type":"Organization",
      "name":"Wipfli",
      "sameAs":["https://www.wipfli.com","https://www.facebook.com/WipfliLLP/","https://www.linkedin.com/company/wipfli-llp","https://twitter.com/Wipfli_LLP","http://www.youtube.com/c/Wipfli "],
      "url":"http://www.wipfli.com",
      "contactPoint":{"@type":"ContactPoint","contactType":"Customer Service","telephone":"+1-414-431-9300"},
      "email":"wipfliinfo@wipfli.com","legalName":"Wipfli LLP",
      "logo":Imagedata},
      "sourceOrganization":{"@type":"Organization","name":"Wipfli",
      "sameAs":["https://www.wipfli.com","https://www.facebook.com/WipfliLLP/","https://www.linkedin.com/company/wipfli-llp","https://twitter.com/Wipfli_LLP","http://www.youtube.com/c/Wipfli "],
      "url":"http://www.wipfli.com","contactPoint":{"@type":"ContactPoint","contactType":"Customer Service","telephone":"+1-414-431-9300"},
      "email":"wipfliinfo@wipfli.com",
      "legalName":"Wipfli LLP",
      "logo":Imagedata}
    };
    return (
      <>
        <Helmet>
         
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
  
       
        
      </>
    );
  }
  
  export default ArticleSchema;