import React, { useRef, useEffect } from 'react';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText/index.js';
import { Link } from 'react-router-dom';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import './index.scss';
import Button from '../Button';
const HeroVariations = ({ fields, sitecoreContext, rendering }) => {
  const heroVideoContentRef = useRef();
  const heroVideoRef = useRef();
  const videosUrl = fields?.Video?.value?.src ? fields?.Video?.value?.src : fields?.VideoUrl?.value;

  const playVideo = (e) => {
    const target = e.currentTarget;
    const videoUrls = target.getAttribute('data-video-url');
    heroVideoContentRef?.current.classList.add('show-modal');
    const newVideoUrl = `${videoUrls}?autoplay=1&loop=1&color=0050ff`;
    heroVideoRef?.current.setAttribute('src', newVideoUrl);
    document.querySelector('.hero__video-modal-close-btn').focus();
  };

  const closeVideoModal = () => {
    if (heroVideoContentRef?.current.classList.contains('show-modal')) {
      heroVideoContentRef?.current.classList.remove('show-modal');
      heroVideoRef?.current.setAttribute('src', '');
      document.querySelector('.hero__play-btn').focus();
    }
  };
  const closeOutsideModal = (event) => {
    if (event.target === heroVideoContentRef?.current) {
      closeVideoModal();
    }
  };

  const imageLoad = () => {
    const heroimages = document.querySelectorAll('.hero__images');

    if (heroimages.length > 0) {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      };

      function observerCallback(entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show-image');
          }
        });
      }

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      heroimages.forEach((el) => observer.observe(el));
    }
  };
  useEffect(() => {
    const Comp = document.querySelector('#hero');
    var anchorscall = Comp.querySelectorAll('a,button');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if (element.innerText != '') {
        var id =
          sitecoreContext?.route?.name +
          '-ds:' +
          rendering.dataSource +
          '-Hero-Variations-' +
          element.innerText.replaceAll(' ', '-');

        element.setAttribute('id', id);
      }
    });
    imageLoad();

    document.addEventListener('keyup', (e) => {
      // close video modal on key press of escape
      if (e.key === 'Escape') {
        closeVideoModal();
      }
    });

    window.addEventListener('click', closeOutsideModal, false);

    return () => {
      window.removeEventListener('keyup', closeVideoModal);
      window.removeEventListener('click', closeOutsideModal);
      window.removeEventListener('load', imageLoad);

      document.body.classList.remove('open-navigation');
      document.body.classList.remove('header__scroll-up');
      document.body.classList.remove('header__scroll-down');
    };
  }, []);

  return (
    <section
      className={`hero ${
        fields?.ContentArrangement?.value
          ? fields?.ContentArrangement?.value
          : 'hero-imagegallery-variation__one'
      }`}
      data-full-image={`${
        //fields?.VideoUrl?.value ||  fields?.Video?.value?.src|| fields?.BackgroundImage?.value?.src ? 'true' : 'false'
        fields?.ContentArrangement?.value == 'hero-imagegallery-variation__withimage'
          ? 'true'
          : 'false'
      }`}
      id="hero"
    >
      <div className="hero__inner-contain">
        <div className="hero__container">
          <div className="hero__inner-content">
            {fields?.ShowBreadCrumb?.value == true && (
              <Link className="hero__pretitle" to={fields?.BreadCrumb?.value?.href}>
                {fields?.BreadCrumb?.value?.text}
              </Link>
            )}
            {fields?.Title?.value && (
              <h1 className="hero__title">
                <RoutableRichText field={fields?.Title} />
              </h1>
            )}
            {fields?.BackgroundImage?.value?.src && (
              <Image
                media={fields?.BackgroundImage}
                id="heromobileImage"
                data-id="hero-mobile-first-image"
              />
            )}
          </div>
          <div className="hero__description-contain">
            {fields?.Description?.value && (
              <div className="hero__description">
                <RoutableRichText field={fields?.Description} />
              </div>
            )}
            {fields?.ContentArrangement?.value != 'hero-imagegallery-variation__withimage' ? (
              <>
                {fields?.CTA?.value?.href && (
                  <div className="hero__link">
                    <Button field={fields?.CTA} variant={'btn-trans-blue'} />
                  </div>
                )}
              </>
            ) : (
              <>
                {(fields?.VideoUrl?.value || fields?.Video?.value?.src) && (
                  <div className="hero__play-btn-contain">
                    <button
                      className="hero__play-btn"
                      data-video-url={videosUrl}
                      title={`click here to ${fields?.VideoCTAText.value}`}
                      onClick={playVideo}
                      aria-controls="heroVideoContainer"
                    >
                      {fields?.VideoCTAText.value}
                      <svg
                        aria-hidden="true"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 0a8.5 8.5 0 1 1 0 17 8.5 8.5 0 0 1 0-17zM5.224 4.395v8.127l8.127-4.063-8.127-4.064z"
                          fill="#FFF"
                          fillRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          {fields?.ContentArrangement?.value == 'hero-imagegallery-variation__withimage' ? (
            <></>
          ) : (
            <div>
              {fields?.LottieImage1?.value != '' ? (
                <div data-id="hero-first-image">
                  {fields?.LottieImage1?.value.toLowerCase().includes('<lottie-player') ? (
                    <div dangerouslySetInnerHTML={{ __html: fields?.LottieImage1?.value }} />
                  ) : (
                    <Player autoplay speed={1.5} loop src={fields?.LottieImage1?.value}></Player>
                  )}
                </div>
              ) : (
                fields?.Image1?.value?.src != null && (
                  <Image
                    media={fields?.Image1}
                    id="heroFirstImage"
                    data-id="hero-first-image"
                    className="hero__images"
                  />
                )
              )}
              {fields?.LottieImage2?.value != '' ? (
                <div data-id="hero-second-image">
                  {fields?.LottieImage2?.value.toLowerCase().includes('<lottie-player') ? (
                    <div dangerouslySetInnerHTML={{ __html: fields?.LottieImage2?.value }} />
                  ) : (
                    <Player autoplay speed={1.5} loop src={fields?.LottieImage2?.value}></Player>
                  )}
                </div>
              ) : (
                fields?.Image2?.value?.src != null && (
                  <Image
                    media={fields?.Image2}
                    id="heroSecondImage"
                    data-id="hero-second-image"
                    className="hero__images"
                  />
                )
              )}
              {fields?.LottieImage3?.value != '' ? (
                <div data-id="hero-third-image">
                  {fields?.LottieImage3?.value.toLowerCase().includes('<lottie-player') ? (
                    <div dangerouslySetInnerHTML={{ __html: fields?.LottieImage3?.value }} />
                  ) : (
                    <Player autoplay speed={1.5} loop src={fields?.LottieImage3?.value}></Player>
                  )}
                </div>
              ) : (
                fields?.Image3?.value?.src != null && (
                  <Image
                    media={fields?.Image3}
                    id="heroThirdImage"
                    data-id="hero-third-image"
                    className="hero__images"
                  />
                )
              )}
            </div>
          )}
        </div>

        {fields?.ContentArrangement?.value == 'hero-imagegallery-variation__withimage' ? (
          <Image media={fields?.BackgroundImage} id="heroBgImage" data-id="hero-bg-image" />
        ) : (
          <></>
        )}
      </div>
      {(fields?.VideoUrl?.value || fields?.Video?.value?.src) && (
        <div
          className="hero__video-modal-container"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
          ref={heroVideoContentRef}
          id="heroVideoContainer"
        >
          <div className="hero__video-modal">
            <button
              className="hero__video-modal-close-btn"
              title="click here to close the modal"
              onClick={closeVideoModal}
            />
            <div className="hero__video-modal-contain">
              {videosUrl.match(/(?:www\.)?player.vimeo|vimeo.com|youtu(?:\.be|be\.com)/i) !=
              null ? (
                <iframe
                  className="hero__video"
                  title="hero video"
                  allow="autoplay; fullscreen"
                  playsInline
                  allowFullScreen
                  ref={heroVideoRef}
                />
              ) : (
                <video
                  className="hero__video"
                  title="hero video"
                  playsInline
                  allowFullScreen
                  autoPlay
                  loop
                  controls
                  ref={heroVideoRef}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default withSitecoreContext()(HeroVariations);
