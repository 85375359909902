import React from 'react';
import { RoutableRichText } from '../../react/RoutableRichText';
import './index.scss';

const ArticleText = ({ fields }) => {
  return (
    <section className="article-text">
      {fields?.text?.value && <RoutableRichText field={fields?.text} />}
    </section>
  );
};

export default ArticleText;
