import React from 'react';
import './index.scss';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText';
import { Link } from 'react-router-dom';

const TwoColumnForm = ({ rendering }) => {
  const { fields = {} } = rendering;

  return (
    <section className="two-column-form">
      <div className="two-column-form__container">
        <div className="two-column-form__column-left">
          {fields?.PreText?.value && (
            <div className="two-column-form__pretitle-contain">
              <Text field={fields?.PreText} tag="p" className="two-column-form__pretitle" />
            </div>
          )}
          {fields?.Title?.value && (
            <Text field={fields?.Title} tag="h2" className="two-column-form__title" />
          )}
          {fields?.Title?.value && (
            <div className="two-column-form__description">
              <RoutableRichText field={fields?.Description} />
            </div>
          )}
        </div>
        <div className="two-column-form__column-right">
          <Placeholder name="jss-form" rendering={rendering}></Placeholder>
        </div>
      </div>
    </section>
  );
};

export default TwoColumnForm;
